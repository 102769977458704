import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import ProjectAddPage from './ProjectAddPage'
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const ProjectListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();


  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color:'#079327'}}>{t(data.status_text)}</label>];
  }

  const getCardBackgroundColor=(data)=>{
        if(data.project_status === 2){
            return '#dfede2'
        }
    }

  const config = {
    url: '/api/v1/projects/',
    pageTitle: t('project_list_title'),
    deleteTitle: t('project_delete_confirmation_text'),
    pageSubtitle: t('project_list_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'project_name',
    cardDetailButtonText:  t('listpage_detail_button_text') ,
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'duration': t('project_duration_field'), 'site_manager_name': t('project_sitemanager_field')},
    cardFooterFields: [],
    orderingFields: [
        {id: '-project_name', name: t('project_orderby_field1')},
        {id: 'project_name', name: t('project_orderby_field2')},
         {id: '-created', name: t('project_orderby_field3')},
         {id: 'created', name: t('project_orderby_field4')}
    ],
    addPermission: ['project-create'],
    editPermission: ['project-edit'],
    deletePermission: ['project-delete'],
    viewPermission: ['project-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    'getCardBackgroundColor': getCardBackgroundColor,
  }
  const handleOpenModal = (item, itemEdit) => {
    if(itemEdit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setEdit(false);
    setItem(null);
    setShowModal(false);
  };
  const handleSuccessAdd = () =>{
    setEdit(false);
    setItem(null);
    setReload(!reload);
    setShowModal(false);
  }

  const handleDeleteClick = (item)=>{
    http.delete(`/api/v1/projects/${item.id}/delete/`).then((resp)=>{
      if(resp.status === 204) {
        setReload(!reload);
      }
      else{
        message.error(t('project_delete_failed'))
      }
    }).catch((err)=>{
      if(err.response){
        message.error(t(err.response.data.message));
      }
    })
  }

  const handleDetailClick=(item)=>{
    navigate('/project/detail/', { replace: true, state: {project: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <ProjectAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            edit={edit}
            editItem={item}
        />
      </>
  );
};

export default ProjectListPage;
