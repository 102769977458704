import React, {useEffect, useState} from "react";
import {Button, message, Table, Row, Col, Space} from "antd";
import csvtojson from "csvtojson";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import http from "../../http";

const CSVFIlePreview = ({ url, columns, title, downloadFileName, headers, handleUploadButtonClick, requiredPermission, hideUploadButton}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const fetchData = () => {
    setLoading(true);
    if(url) {
      try {
        http.get(url).then((response) => {
          const csvString = response.data;
          csvtojson({headers: headers}).fromString(csvString).then((value) => {
            setData(value);
          });

        }).catch((err)=>{
          console.log(err)
        });

      } catch (error) {
        message.error(t("csvfile_load_error"));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(()=>{
    if(url) {
      fetchData();
    }
  }, [url])

  const uploadCSV = async ()=>{
    if(handleUploadButtonClick){
      handleUploadButtonClick()
    }
    else{
      message.error('Not Implemented')
    }
  }

  const downloadCsv = () => {
    try {
      if(url) {
        http.get(url).then((response) => {
          const csvString = response.data;
          const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
          const fileUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute("download", downloadFileName || "data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          message.error(t("csv_file_download_error"));
        })
      }
      else{
        message.error("No File")
      }
    }
    catch (err){
      console.log("Error Download")
    }
  };

  // Adjust columns for better responsiveness
  const adjustedColumns = columns.map(col => ({
    ...col,
    onCell: () => ({
      style: {
        minWidth: 150, // Adjust this value as needed
      },
    }),
  }));

  return (
      <>
        <Row style={{justifyContent: 'space-between', alignItems:'start', marginBottom:'10px'}}>
          <Col style={{fontWeight:"bold", fontSize: 15}}>
            {title}
          </Col>
          <Col>
            <Space>
              {url ? <PermissionChecker requiredPermissions={requiredPermission || []}>
                <Button className={'bordered-default-button'} onClick={()=>downloadCsv()}>
                  {t('download_button_text')}
                </Button>
              </PermissionChecker>:<></>}
              {!hideUploadButton ? <PermissionChecker requiredPermissions={requiredPermission || []}>
                <Button style={{border: "none"}} type={'primary'} onClick={()=>uploadCSV()}>
                  {t('upload_button_text')}
                </Button>
              </PermissionChecker>:null}
            </Space>
          </Col>
        </Row>
        <Row style={{overflowY: 'scroll', width: '100%', height: '100%'}}>
          {data.length > 0 && (
              <Table
                  loading={loading}
                  columns={adjustedColumns}
                  dataSource={data}
                  rowKey="_id"
                  pagination={false}
                  style={{width: '100%'}}
                  sticky={true}
                  scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
              />
          )}
        </Row>
      </>
  );
};

CSVFIlePreview.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  downloadFileName: PropTypes.string,
};

export default CSVFIlePreview;
