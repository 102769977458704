import React, { useEffect } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import markerIcon from "../../assets/icons/marker.png";

const MachinePosition = () => {
  useEffect(() => {
    // Create the map
    const map = new Map({
      target: 'map',
      layers: [
        // Load the map tiles from maps.gsi.go.jp
        new TileLayer({
          source: new XYZ({
            url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([139.6917, 35.6895]), // Tokyo, Japan coordinates
        zoom: 15,
      }),
    });

    // Create a marker at a specific location
    const marker = new Feature({
      geometry: new Point(fromLonLat([139.6917, 35.6895])), // Coordinates for Tokyo
    });

    // Define marker style
    marker.setStyle(
        new Style({
          image: new Icon({
            src: markerIcon, // Marker image
            scale:0.08,
          }),
        })
    );

    // Add the marker layer to the map
    const vectorSource = new VectorSource({
      features: [marker],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    map.addLayer(vectorLayer);

    return () => map.setTarget(null); // Cleanup map on unmount
  }, []);

  return (
        <div id="map" style={{ width: '100%', height: '250px', position: 'relative' }}>
          {/* The map element */}
          <div id="map-container"></div>

          {/* Attribution as an overlay */}
          <div style={{ position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'white', padding: '5px', fontSize: '12px', color:'black', zIndex:10 }}>
            Map tiles © <a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank" rel="noopener noreferrer">Geospatial Information Authority of Japan</a>
          </div>
        </div>
  );
};

export default MachinePosition;