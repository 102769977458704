import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const MachineProjectListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color:'#079327'}}>{t(data.status_text)}</label>];
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: props.url || `/api/v1/projects/?machine=${props.machine.id}`,
    pageTitle: t('project_list_title'),
    pageSubtitle: t('project_list_subtitle'),
    hideEdit: true,
    disableDelete: true,
    hideAddButton: true,
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'project_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'duration': t('project_duration_field'),
      'site_manager_name': t('project_sitemanager_field'),
    },
    cardFooterFields: [],
    addPermission: ['project-create'],
    editPermission: ['project-edit'],
    deletePermission: ['project-delete'],
    viewPermission: ['project-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }

  const handleDetailClick=(item)=>{
    navigate('/project/detail/', { replace: false, state: {project: item, breadcrumbs:props.link} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={null}
            onDeleteClick={null}
            reload={reload}
            onDetailClick={(item)=>handleDetailClick(item)}
        />
      </>
  );
};

export default MachineProjectListPage;
