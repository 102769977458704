import React, {useEffect, useState} from 'react';
import 'ol/ol.css';
import {Button, Col, Row, Space} from "antd";
import http from "../../http";
import PermissionChecker from "../../PermissionChecker";
import {useTranslation} from "react-i18next";
import THREEGL from "../../utilities/THREEGL";
import XMLParser from "../../utilities/XMLParser";

const XMLViewer = (props) => {
    const { t } = useTranslation();
    const [xmlData, setXMLData] = useState(null);

    useEffect(()=>{
        const fetchData=()=> {
            http.get(props.downloadURL)
                .then((response) => {
                    let js_elem = document.getElementById(props.id);
                    const threeGL = new THREEGL(js_elem.clientWidth, js_elem.clientHeight, js_elem);
                    const xmlData = new XMLParser(response.data).GetGLObject();
                    threeGL.LoadSurfaceXMLMaps(xmlData);
                    threeGL.UpdateGLSize(js_elem.clientWidth, js_elem.clientHeight);
                    threeGL.ZoomToFitSurface();
                    setXMLData(response.data);
                }).catch((err) => {
                console.log(err)
            });
        }
        if(props.downloadURL) {
            fetchData()
        }
    }, [props.downloadURL])

    const uploadHandler = ()=>{
        if(props.handleUploadButtonClick){
            props.handleUploadButtonClick()
        }
    }

    const downloadHandler = () => {
        // http.get(props.downloadURL)
        //     .then((response) => {
        //         const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', props.downloadFileName || 'file.xml');
        //         document.body.appendChild(link);
        //         link.click();
        //     }).catch((err)=>{
        //     console.log(err)
        // });
        const url = window.URL.createObjectURL(new Blob([xmlData], { type: 'application/xml' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', props.downloadFileName || 'file.xml');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <>
            <Row style={{justifyContent: 'space-between', alignItems:'start', marginBottom:'10px'}}>
                <Col style={{fontWeight:"bold", fontSize: 15}}>
                    {props.title}
                </Col>
                <Col>
                    <Space>
                        {props.downloadURL ? <PermissionChecker requiredPermissions={props.requiredPermission || []}>
                            <Button
                                className={'bordered-default-button'}
                                onClick={()=>downloadHandler()}>
                                {t('download_button_text')}
                            </Button>
                        </PermissionChecker>:<></>}
                        {!props.hideUploadButton ?<PermissionChecker requiredPermissions={props.requiredPermission || []}>
                            <Button type={"primary"} style={{border: "none"}} onClick={()=>uploadHandler()}>
                                {t('upload_button_text')}
                            </Button>
                        </PermissionChecker>:null}
                    </Space>
                </Col>
            </Row>
            <Row style={{height: '100%'}} id={props.id}>

            </Row>
        </>
    )
};

export default XMLViewer;