import {Divider, Row, Col, Table, Space, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import MachineListPage from "../Machines/MachineListPage";
import React, {useEffect, useState} from "react";
import http from '../../http';
import CSVFIlePreview from "../../components/CSVFilePreview/CSVFilePreview";
import DXFViewer from "../../components/DXFFileViewer/DXFViewer";
import AddPageModal from "../../components/AddPage/AddPage";
import {useTranslation} from "react-i18next";
import DataTable from "../../components/DataTable/DataTable";
import XMLViewer from "../../components/XMLFileViewer/XMLViewer";
import {getReadableDateFromTimeStamp} from "../../utilities/common";

const ManifestDetailPage=(props)=>{
    const location = useLocation();
    const {state} = location;
    const [manifest, setManifest] = useState(state?.manifest);
    const [loading, setLoading] = useState(false);
    const [fileuploadModalVisible, setFileuploadModalVisible] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: 10
    })
    const [assignmentPagination, setAssignmentPagination] = useState({
        page: 1,
        page_size: 10
    })
    const [config, setFileUploadConfig] = useState({
        editUrl: `/api/v1/projects/manifests/${state?.manifest?.id}/edit/`,
        pageTitle: 'Upload ',
        cancelButtonText: 'Cancel',
        saveButtonText: 'Upload',
        addSuccessMessage: 'Uploaded Successfully',
        modalSize: 400,
        fields: [

        ]
    });
    const { t } = useTranslation();
    const [assignmentDataSource, setAssignmentDataSource] = useState([]);
    // const [assignmentInitialData, setAssignmentInitialData] = useState({})
    const [historyDataSource, setHistoryDataSource] = useState([]);
    const navigate = useNavigate();
    const [isValidState, setValidState] = useState(!!state?.manifest);

    const fetchManifestData=()=>{
        http.get(`/api/v1/projects/manifests/${state?.manifest?.id}/`).then((resp)=>{
            setLoading(false);
            setManifest(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    const fetchAssignmentData=(pagination_data)=>{
        let pagination_data_value = pagination_data || assignmentPagination;
        setLoading(true);
        http.get(
            `/api/v1/projects/machine-assignments/?manifest=${state?.manifest?.id}`,
            {params:{...pagination_data_value}}).then((resp)=>{
            setAssignmentDataSource(resp.data.results);
            setAssignmentPagination({...assignmentPagination, total:resp.data.count})
            setLoading(false);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    const fetchHistoryData=(pagination_data)=>{
        let pagination_data_value = pagination_data || pagination;
        setLoading(true);
        http.get(
            `/api/v1/auth/history_trackings/?model=Manifest&model_pk=${manifest?.id}`,
            {params:{...pagination_data_value}}).then((resp)=>{
            setHistoryDataSource(resp.data.results);
            setPagination({...pagination, total:resp.data.count})
            setLoading(false);
        }).catch((err)=>{
            setLoading(false);
        })
    }
    const fetchDefaultData =()=>{
        fetchManifestData();
        fetchAssignmentData();
        fetchHistoryData();
    }

    const onPageChange=(current, pageSize)=>{
        fetchHistoryData({page: current, page_size:pageSize})
    }

    const onAssignmentPageChange=(current, pageSize)=>{
        fetchAssignmentData({page: current, page_size:pageSize})
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found', {replace: true})
        }
        if(isValidState) {
            fetchDefaultData()
        }
        return (()=>{})
    }, [state])

    const historyColumns = [
        {
            title: t('manifest_changehostory_col1'),
            dataIndex: 'triggered_date_time',
            key: 'date',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('manifest_changehostory_col2'),
            dataIndex: 'event',
            render: (text) => {
                const parts = text.split(',');
                const lastPart = parts.pop(); // Remove and store the last part
                const lastPartParts = lastPart.split(' '); // Split the last part by space
                const translatedParts = parts.map(part => t(part)); // Translate individual parts
                const translatedLastPart = t(lastPartParts[0]); // Translate the first part of the last item
                const finalString = translatedParts.join(',') + ' ' + translatedLastPart; // Concatenate
                return <div>{finalString + ' ' +t(lastPartParts[1])}</div>
            },
            key: 'event',
        },
        {
            title: t('manifest_changehostory_col3'),
            dataIndex: 'triggered_by_text',
            key: 'triggered_by_text',
        }
    ];

    const columns = [
        {
            title: t('machine_usagerecord_list_col1'),
            dataIndex: 'from_date',
            key: 'from_date',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('machine_usagerecord_list_col2'),
            dataIndex: 'to_date',
            key: 'to_date',
            render: (value) => {
                let readable_timestamp = getReadableDateFromTimeStamp(value);
                return <div>{readable_timestamp}</div>
            }
        },
        {
            title: t('machine_usagerecord_list_col3'),
            dataIndex: 'machine_text',
            key: 'machine_text',
        }
    ]
    const reference_headers = ['name', 'point_x', 'point_y', 'point_z']
    const reference_columns = [
        {
            title: t('referencepoint_col1'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('referencepoint_col2'),
            dataIndex: 'point_x',
            key: 'point_x'
        },
        {
            title: t('referencepoint_col3'),
            dataIndex: 'point_y',
            key: 'point_y'
        },
        {
            title: t('referencepoint_col4'),
            dataIndex: 'point_z',
            key: 'point_z'
        }
    ];

    const localization_headers = ['name', 'point_x', 'point_y', 'point_z', 'latitude', 'longitude', 'altitude']
    const localization_columns = [
        {
            title: t('localization_list_col1'),
            dataIndex: 'name',
            key: 'name',
            width: '10%'
        },
        {
            title: t('localization_list_col2'),
            dataIndex: 'point_x',
            key: 'point_x',
            width: '20%'
        },
        {
            title: t('localization_list_col3'),
            dataIndex: 'point_y',
            key: 'point_y',
            width: '20%'
        },
        {
            title: t('localization_list_col4'),
            dataIndex: 'point_z',
            key: 'point_z',
            width: '20%'
        },
        {
            title: t('localization_list_col5'),
            dataIndex: 'latitude',
            key: 'latitude',
            width: '20%'
        },
        {
            title: t('localization_list_col6'),
            dataIndex: 'longitude',
            key: 'longitude',
            width: '20%'
        },
        {
            title: t('localization_list_col7'),
            dataIndex: 'altitude',
            key: 'altitude',
            width: '20%'
        }
    ];

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state?.breadcrumbs){
            state?.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{manifest?.name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/projects'}>{t('project_list_title')}  </Link> >  {manifest?.name}</>;
    }

    const onFileUploadModalClose=()=>{
        setFileuploadModalVisible(false)
    }
    const onFileUploadFinish=(values, handleError, form)=>{
        const formData = new FormData();
        const requestConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        for (let name in values) {
            const value = values[name];
            if (value instanceof File ) {
                formData.append(name, value, value.name);
            }
            // Append other values to the FormData object
            else if(!value || value === 'undefined' || value === 'null') {
                formData.append(name, '');
            }
            else{
                formData.append(name, value);
            }

        }
        http.patch(config.editUrl, formData, requestConfig).then((resp) => {
            if (resp.status === 200) {
                message.success(config.addSuccessMessage || resp.data.message || 'Uploaded Successfully');
                fetchManifestData();
                fetchHistoryData()
                onFileUploadModalClose();
                form.resetFields();
            }
        }).catch((error) => {
            handleError(error);
        })
    }
    const handleUploadButtonClick = (title, field_name, field_label)=>{
        let config = {
            editUrl: `/api/v1/projects/manifests/${manifest?.id}/edit/`,
            pageTitle: title,
            cancelButtonText: t('addpage_cancel_button_text'),
            saveButtonText: t('file_upload_button_text'),
            addSuccessMessage: t('file_upload_successful_message'),
            modalSize: 400,
            fields: [
                {
                    name: field_name,
                    label: field_label,
                    placeholder: t('select_placeholder'),
                    isRequired: false,
                    requiredMessage: '',
                    type: 'file',
                    colNo: 1,
                    order: 1
                },

            ]
        }
        setFileUploadConfig(config)
        setFileuploadModalVisible(true);

    }
    if(isValidState) {
        return (
            <>
                <AddPageModal config={config} visible={fileuploadModalVisible} onClose={onFileUploadModalClose}
                              edit={true} initialValues={{}} onFinish={onFileUploadFinish}/>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col style={{lineHeight: '1.5rem'}}><h1>{manifest?.name}</h1></Col>
                        <Col>{t('manifest_constructiontype_field')}: {t(manifest?.construction_type_text)}</Col>
                        <Col>{t('manifest_machinetype_field')}: {t(manifest?.machine_type_text)}</Col>
                    </Row>

                    <Divider/>
                    <Row style={{overflowY: "hidden", height: '50%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <CSVFIlePreview
                                requiredPermission={['manifest-edit']}
                                url={manifest?.reference ? `/api/v1/projects/manifests/${manifest?.id}/site_reference_data/` : ''}
                                title={`(${manifest?.reference_file_name})` + t('reference_point_list_title')}
                                columns={reference_columns}
                                headers={reference_headers}
                                downloadFileName={manifest?.reference_file_name}
                                handleUploadButtonClick={() => handleUploadButtonClick(t('reference_file_upload_header'), 'reference', t('manifest_reference_field'))}
                                hideUploadButton={manifest?.active_manifest}
                            />
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '50%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <DXFViewer
                                reload={loading}
                                requiredPermission={['manifest-edit']}
                                title={`(${manifest?.site_data_2d_file_name})` + t('dxf_file_list_title')}
                                downloadURL={manifest?.site_data_2d}
                                downloadFileName={manifest?.site_data_2d_file_name}
                                geojsonUrl={manifest?.site_data_2d ? `/api/v1/projects/manifests/${manifest?.id}/site_data_2d/` : ''}
                                handleUploadButtonClick={() => handleUploadButtonClick(t('dxf_file_upload_header'), 'site_data_2d', t('manifest_dxf_field'))}
                                hideUploadButton={manifest?.active_manifest}
                            />
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '50vh'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <XMLViewer
                                requiredPermission={['manifest-edit']}
                                handleUploadButtonClick={() => handleUploadButtonClick(t('xml_file_upload_header'), 'site_data', t('manifest_xml_fields'))}
                                title={`(${manifest?.site_data_file_name})` + t('xml_file_list_title')}
                                downloadURL={manifest?.site_data ? `/api/v1/projects/manifests/${manifest?.id}/site_xml_data/`: ''}
                                downloadFileName={manifest?.site_data_file_name}
                                hideUploadButton={manifest?.active_manifest}
                                id={`main_xml_${manifest?.id}`}
                            />
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '50vh'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <XMLViewer
                                requiredPermission={['manifest-edit']}
                                handleUploadButtonClick={() => handleUploadButtonClick(t('xml_file_upload_header'), 'site_data_sub', t('manifest_sub_xml_field'))}
                                title={`(${manifest?.site_data_sub_file_name})` + t('sub_xml_file_list_title')}
                                downloadURL={manifest?.site_data_sub ? `/api/v1/projects/manifests/${manifest?.id}/site_subxml_data/`: ''}
                                downloadFileName={manifest?.site_data_sub_file_name}
                                hideUploadButton={manifest?.active_manifest}
                                id={`sub_xml_${manifest?.id}`}
                            />

                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflowY: "hidden", height: '50%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <CSVFIlePreview
                                requiredPermission={['manifest-edit']}
                                url={manifest?.localization ? `/api/v1/projects/manifests/${manifest?.id}/site_localization_data/` : ''}
                                title={`(${manifest?.localization_file_name})` + t('localization_file_list_title')}
                                columns={localization_columns}
                                headers={localization_headers}
                                downloadFileName={manifest?.localization_file_name}
                                handleUploadButtonClick={() => handleUploadButtonClick(t('localization_file_upload_header'), 'localization', t('manifest_localization_field'))}
                                hideUploadButton={manifest?.active_manifest}
                            />
                        </div>
                    </Row>
                    <Divider/>
                    <Row style={{overflow: "hidden", height: '65%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <MachineListPage pageSize={3} manifest={manifest}
                                             url={`/api/v1/machines/?manifest=${manifest?.id}`}
                                             title={t('manifest_machine_listpage_title')} hideEdit={true}
                                             hideDelete={true}/>
                        </div>
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{
                                fontWeight: "bold",
                                fontSize: "800"
                            }}> {t('manifest_assignment_recordslist_title')} </p>
                        </Col>
                        <Col>
                            <DataTable
                                sticky={true}
                                loading={loading}
                                data={assignmentDataSource}
                                columns={columns}
                                pagination={{...assignmentPagination, onChange: onAssignmentPageChange}}
                            />
                        </Col>
                    </Row>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{
                                fontWeight: "bold",
                                fontSize: "800"
                            }}> {t('manifest_change_historylist_title')} </p>
                        </Col>
                        <Col>
                            <DataTable
                                sticky={true}
                                loading={loading}
                                data={historyDataSource}
                                columns={historyColumns}
                                pagination={{...pagination, onChange: onPageChange}}/>
                        </Col>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default ManifestDetailPage;