import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Spin} from "antd";
import {useData} from "./DataContext";

const checkAllowed = (requiredPermission, permissionList) => {
    if (!requiredPermission) {
        return true; // No required permissions specified, so always allowed
    }

    return requiredPermission.every(permission =>
        permissionList.includes(permission)
    );
};
const PrivateRoute = ({...rest}) => {
    const spinning = useSelector((state) => state.auth.spinning) || false;
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const {sharedData} = useData();
    const storedPermissions =  useSelector((state) => state.auth.permissions);
    const permissionList = sharedData.permitted_urls || storedPermissions || [];
    const isSuperuser =  useSelector((state) => state.auth.is_superuser)
    const is_superuser = parseInt(sharedData.is_superuser || "0") || parseInt(isSuperuser || "0") || null;
    let redirectPath = '/login';
    let permissionDeniedPath = '/permission_denied';
    let isAllowed = is_superuser || checkAllowed(rest.requiredPermission, permissionList);
    if(rest.path === '/login'){
        if (!isLoggedIn) {
            return rest.children;
        }
        return is_superuser ? <Navigate to={'/system_infos'} /> : <Navigate to={'/'} />;
    }
    if(is_superuser && (rest.path === '' || rest.path === '/')){
        return <Navigate to={'/system_infos'} />
    }
    if (!isLoggedIn) {
        return <Navigate to={redirectPath} replace />;
    }
    if (!isAllowed) {
        return <Navigate to={permissionDeniedPath} />;
    }
    return <>
        {spinning ?(
            <Spin
                spinning={spinning}
                size="large"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                }}
            />
        ): rest.children}

    </>
}
export default PrivateRoute;